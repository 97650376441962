import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody } from "app/components/Modal/components";
import Modal from "app/components/Modal";
import { H2, P1 } from "app/components/Typography";
import Button from "app/components/Button";
import Div from "app/components/Div";
import Thumbnail from "app/components/Thumbnail";
import Text from "app/components/Text";
import env from "helpers/env";

const PartyModal = ({
  content,
  handleClick,
  afterOpen,
  isOpen,
  classData,
  toggleModal,
}) => {
  return (
    <Modal
      toggleModal={toggleModal}
      afterOpen={afterOpen}
      isOpen={isOpen}
      maxWidth="330px"
    >
      <ModalHeader variant="light" toggleModal={toggleModal}>
        <H2>{content.headerTitle}</H2>
      </ModalHeader>
      <ModalBody mt={3}>
        {classData && (
          <Div position="relative">
            <Thumbnail
              borderRadius="8px"
              src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classData.slug
              }-basic.jpg?w=1200`}
              placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classData.slug
              }-basic.jpg?blur=200&px=16&auto=format`}
            />
            <Div position="absolute" bottom={0} p={2}>
              <Text
                fontSize="12px"
                textTransform="uppercase"
                color="white"
                letterSpacing="1.2px"
              >
                <Text fontWeight="bold">{classData.title}</Text>
                {classData.instructor?.name}
              </Text>
            </Div>
          </Div>
        )}
        <P1 mt={3}>{content.body}</P1>
        {handleClick && (
          <Button mt={3} onClick={handleClick}>
            {content.callToAction}
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

PartyModal.defaultProps = {
  afterOpen: null,
  classData: null,
  error: {},
  isOpen: false,
  toggleModal: null,
};

PartyModal.propTypes = {
  afterOpen: PropTypes.func,
  classData: PropTypes.shape({}),
  content: PropTypes.shape({}).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default PartyModal;
